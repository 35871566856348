<template>
  <div class="info-component">
    <div class="first-row-info">
      <div class="d-flex" style="align-items: center">
        <avatar :avatarLetter="$service.getFirstLetter(localOpName)" :size="3" />

        <div class="d-flex" style="flex-direction: column; text-align: left">
          <div v-if="!isEditingOpName" @click="editOpportunityName()" class="opportunity-name">
            {{ localOpName }}

            <i v-if="editable" class="icon-pencil-line hovering-edit" />
          </div>

          <input
            v-else
            @blur="editOpportunityName()"
            id="op-name-input"
            class="op-name-input"
            type="text"
            v-model="localOpName"
          />

          <div @click="copyNumber(opportunity.id)" class="op-id">
            {{ "#" + opportunity.id }}
          </div>
        </div>
      </div>

      <div class="third-row-buttons">
        <b-dropdown id="drop-actions">
          <template #button-content>
            <i class="icon-phone-chat-icon" style="font-size: 18px" />
          </template>

          <b-dropdown-item @click="goToWhatsapp">
            <i class="icon-whatsapp-chat-icon" style="font-size: 18px; color: #2ed47a" />

            Ir para Whatsapp
          </b-dropdown-item>

          <b-dropdown-item v-if="!isIOS" @click="triggerSingleVoipCall()">
            <i class="icon-phone-chat-icon" style="font-size: 18px" />

            Realizar Ligação (Voip)
          </b-dropdown-item>

          <b-dropdown-item @click="openCall()">
            <i class="icon-phone-chat-icon" style="font-size: 18px" />

            Realizar Ligação
          </b-dropdown-item>

          <b-dropdown-item
            v-if="opportunity.contact.email"
            @click="openEmail(opportunity.contact.email)"
          >
            <i class="icon-email-svgrepo-com" style="font-size: 18px" />

            Enviar E-mail
          </b-dropdown-item>

          <b-dropdown-item @click="copyNumber(opportunity.contact.phone_number)">
            <i class="icon-copy" style="font-size: 19px" />

            Copiar Número
          </b-dropdown-item>
        </b-dropdown>

        <b-button
          v-if="isOpenRoom && checkPermissionInRoom()"
          @click="closeRoom()"
          class="button-style"
          style="background: #046af3 !important"
        >
          <i class="icon-check" />
        </b-button>
      </div>
    </div>

    <div class="d-flex w-100" style="gap: 25px">
      <div class="second-row-info" v-if="fromChat">
        <div style="display: flex; align-items: center">
          <i class="icon-imbot" style="font-size: 16px; margin-left: -3px; margin-right: 3px" />

          {{ newestChat.bot_name }}
        </div>

        <div style="display: flex; align-items: center">
          <i class="icon-information-circle-outline" style="margin-right: 5px" />

          {{ "Sala: #" + newestChat.id + " | " + "Status: " + (isOpenRoom ? "Aberta" : "Fechada") }}
        </div>
      </div>

      <div class="second-row-info" v-if="!fromChat">
        <div style="display: flex; align-items: center">
          <feather-icon icon="Share2Icon" size="12" style="margin-right: 5px" />

          {{ opportunity.media ? opportunity.media.name : "Sem mídia" }}
        </div>

        <div style="display: flex; align-items: center">
          <i class="icon-icon-notebook" style="margin-right: 5px" />

          {{ opportunity.workspace.name }}
        </div>
      </div>

      <div class="second-row-when-chat" v-if="!fromChat && newestChat">
        <div style="display: flex; align-items: center">
          <i class="icon-imbot" style="font-size: 16px; margin-left: -3px; margin-right: 3px" />

          {{ newestChat.bot_name }}
        </div>

        <div style="display: flex; align-items: center">
          <i class="icon-information-circle-outline" style="margin-right: 5px" />

          {{ "Sala: #" + newestChat.id + " | " + "Status: " + (isOpenRoom ? "Aberta" : "Fechada") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";
import { BButton, BDropdown, BDropdownItem } from "bootstrap-vue";
import vSelect from "vue-select";

import { openEmailChannel, openWhatsappChannel, openPhoneChannel } from "@/services/callService";

export default {
  components: {
    Avatar,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
  },

  props: {
    opportunity: {
      type: Object,
      required: true,
      default: null,
    },

    fromChat: {
      type: Boolean,
      required: false,
      default: false,
    },

    fromOmnichannelDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },

    newestChat: {
      required: true,
      default: function () {
        return {};
      },
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },

    value: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      localOpName: this.value || this.opportunity.name,

      isEditingOpName: false,
    };
  },

  computed: {
    seller() {
      return this.$store.getters["app/getUserData"];
    },

    currentWorkspace() {
      return this.$store.getters["app/getUserWorkspace"];
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },

    role() {
      return this.$store.getters["app/getUserRole"];
    },

    isIOS() {
      const userAgent = navigator.userAgent;

      return /iPhone|iPad|iPod/i.test(userAgent);
    },

    isOpenRoom() {
      if (!this.newestChat) {
        return false;
      }

      return this.newestChat.closed_at === undefined || this.newestChat.closed_at === null;
    },
  },

  watch: {
    value() {
      this.localOpName = this.value;
    },

    opportunity: {
      handler() {
        this.localOpName = this.opportunity.name;
      },
      deep: true,
    },
  },

  methods: {
    checkPermissionInRoom() {
      if (!this.newestChat) return true;

      return !(this.newestChat.user_id != this.user.id && this.role == "salesRep");
    },

    getFirstName(userName) {
      return this.$service.toCapitalize(this.$service.splitFirstName(userName));
    },

    editOpportunityName() {
      if (!this.editable) return;

      this.isEditingOpName = !this.isEditingOpName;

      if (this.isEditingOpName) {
        this.$nextTick(() => {
          const input = document.getElementById("op-name-input");

          input?.focus();
        });

        return;
      }

      this.$emit("input", this.localOpName);
    },

    goToWhatsapp() {
      const informations = {
        seller_name: this.getFirstName(this.seller.name),
        opportunity: this.opportunity,
        workspace: this.currentWorkspace.name,
      };

      openWhatsappChannel(informations);

      this.$emit("new-task");
    },

    triggerSingleVoipCall() {
      this.$emit("singleVoipCall");
    },

    openCall() {
      openPhoneChannel(this.opportunity.contact.phone_number);

      this.$emit("new-task");
    },

    openEmail(email) {
      openEmailChannel(email);

      this.$emit("new-task");
    },

    closeRoom() {
      this.$emit("closeByChat");
    },

    async copyNumber(number) {
      const textToCopy = number;

      try {
        await navigator.clipboard.writeText(textToCopy);
      } catch (err) {
        const tempInput = document.createElement("textarea");
        tempInput.value = textToCopy;
        document.body.appendChild(tempInput);

        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // Para dispositivos móveis

        try {
          document.execCommand("copy");
        } catch (err) {
          console.log(err);
        }

        document.body.removeChild(tempInput);
      }

      this.$bvToast.toast("Copiado com sucesso!", {
        title: "Sucesso",
        autoHideDelay: 2000,
        variant: "success",
        toaster: "b-toaster-top-left",
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 7px 10px;
  gap: 5px;
  background: #ffffff;
  overflow: visible;

  .opportunity-name {
    font-size: 16px;
    align-items: center;
    color: #171717;
    cursor: pointer;

    .hovering-edit {
      display: none;
      color: #046af3;
    }
  }

  .op-name-input {
    font-size: 16px;
    width: 100%;
    padding: 4px 5px;
    margin-bottom: 4px;
    border-radius: 6px;
    border: solid 1px #d2d2d2;
    outline: none;
    color: #171717;
  }

  .op-id {
    font-size: 12px;
    color: #046af3;
    cursor: pointer;
  }

  .first-row-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .second-row-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 5px;
  }

  .second-row-when-chat {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    height: 100%;
    margin-left: 5px;
  }

  .third-row-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    .button-style {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 35px;
      padding: 0;
      border-radius: 8px;
      border: none;
    }

    #drop-actions {
      color: #ffffff;
      cursor: pointer;
    }

    :deep(#drop-actions) {
      #drop-actions__BV_toggle_ {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 35px;
        padding: 0;
        border-radius: 8px;
        border: none;
        background: #2ed47a !important;
      }

      .dropdown-toggle::after {
        display: none;
      }

      .dropdown-item {
        display: flex;
        align-items: center;
        gap: 10px;

        i {
          color: #046af3;
        }
      }
    }
  }
}

.info-component:hover {
  .hovering-edit {
    display: inline;
  }
}
</style>
