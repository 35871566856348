import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import { ToastService } from "@/services/toastService";
import VueCompositionAPI from "@vue/composition-api";
import VueCookie from "vue-cookie";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import money from "v-money";
import Service from "@/services/service";
import * as Sentry from "@sentry/vue";
import { initializeFirebase } from "@/services/firebaseService.js";

// Register the component globally
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
// Axios Mock Adapter
// import '@/@fake-db/db'

const messaging = initializeFirebase(store);
Vue.prototype.$messaging = messaging;

const faviconLink = document.getElementById("dinamic-favicon");

store.dispatch("whiteLabel/setPlatform");
let platformData = store.getters["whiteLabel/getPlatform"];

faviconLink.href = platformData.favicon;

Vue.prototype.moment = moment;

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueCookie);

// Composition API
Vue.use(VueCompositionAPI);

// Money default
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  precision: 2,
  masked: false,
});

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.directive("ripple", Ripple);

Vue.config.productionTip = false;

Vue.prototype.$service = Service;

if (window.location.host != "localhost:8080" && window.location.host != "crm.imlabs.local:8080") {
  Sentry.init({
    Vue,
    dsn: "https://5e4fb03bc2bb458497d7c1de2f41e124@gt.institutomix.com.br/6",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release: "20250408",

    ignoreErrors: [
      /vuedraggable\/dist\/vuedraggable\.umd/,
      "request.onerror",
      "request.ontimeout",
      /SyntaxError: Unexpected token '<'/,
      /TypeError: Cannot read properties of null \(reading 'Sortable.+'\)/,
      /FirebaseError: Messaging: This browser doesn't support the API's required to use the Firebase/,
      /FirebaseError/,
      /AxiosError: Request failed with status code 401/,
      /AxiosError: Request failed with status code 403/,
      /AxiosError: Request failed with status code 404/,
      /AxiosError: Network Error/,
      /AxiosError: timeout of \d+ms exceeded/,
      /Navigation cancelled from ".*" to ".*" with a new navigation/,
    ],
  });
}

function injectClarity() {
  if (!process.env.MICROSOFT_CLARIFY_API_KEY) return;

  const script = document.createElement("script");
  const scriptContent = document.createTextNode(`(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${process.env.MICROSOFT_CLARIFY_API_KEY}");`);

  script.appendChild(scriptContent);
  document.head.appendChild(script);
}

injectClarity();

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    ToastService.setVueInstance(this);
  },
}).$mount("#app");
